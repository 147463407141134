.header {
	@apply items-center flex sticky w-full h-[6.5vh] bg-[#0B0D2B] py-1;
}

.header_logo {
	@apply items-center cursor-pointer object-contain h-full w-fit mx-2 my-0;
}

.header_logo_text {
	@apply text-white text-[1rem] w-fit;
}

.header_search {
	@apply items-center flex flex-row w-3/5 rounded-3xl;
}

.header_searchInput {
	@apply h-[35px] w-3/5 p-2.5 rounded-[5px] border-none border-2 border-solid border-[#e88b0000] focus:outline-none min-w-[40%];
}

.header_searchInput:hover {
	@apply shadow-[1px_1px_5px_1px_#e88a00] border-2 border-solid border-[#e88a00];
}

.header_search_button {
	@apply cursor-pointer w-[70px] h-[35px] bg-[#e88a00] text-[aliceblue] border-none text-[1rem] font-bold ml-2.5 rounded-[5px];
}

.header_search_button:hover {
	@apply bg-[#ff9800];
}

.header_signin_logo {
	@apply h-[30px];
}

.header_optionLineOne {
	@apply text-[10px];
}

.header_optionLineTwo {
	@apply text-[13px] font-extrabold;
}

.header_nav {
	@apply flex justify-around items-center h-full ml-auto;
}

.header_nav > Link {
	@apply no-underline;
}

.header_option {
	@apply flex flex-row text-white whitespace-nowrap cursor-pointer ml-[5px] mr-[5px] p-[5px] underline;
}

#greeting {
	@apply font-bold no-underline;
	
}

.option__basket {
	@apply flex object-contain h-[30px] items-center scale-125;
}

.header__optionBasket {
	@apply flex flex-row items-center text-white no-underline;
}

.header__basketCount {
	@apply px-2.5;
}
