.searchPage {
    @apply flex justify-center ml-auto mr-auto;
}

.homeContainer {
    @apply h-full w-full min-h-screen flex flex-col justify-center ml-auto mr-auto;
}

.backgroundGradient {
    @apply flex flex-col items-center w-full h-full min-h-screen bg-center bg-no-repeat;
    background-image: 
      radial-gradient(circle at 100% 100%, rgb(21, 74, 107) 0%, rgba(21, 74, 107, 0) 40%),
      radial-gradient(circle at 0% 0%, rgba(45, 33, 88, 1) 0%, rgba(30, 41, 59, 0) 40%),
      linear-gradient(90deg, rgba(13, 14, 56, 1) 0%, rgba(13, 14, 56, 1) 100%);
    background-size: 100% 100%, 100% 100%, 100% 100%;
    background-position: center, center, center;
    background-repeat: no-repeat, no-repeat, no-repeat;
  }

.searchHeadlineContiner{
    @apply flex flex-row items-center w-fit h-fit gap-2 self-start left-[5%] relative mt-3 mb-2;
}

.searchTermText {
    @apply text-[white] text-[24px] not-italic font-medium leading-[normal] relative self-start;
}

@media (max-width: 800px) {
  .searchTermText {
    @apply text-[white] text-[15px] not-italic font-normal leading-[normal] relative self-start;
}
}