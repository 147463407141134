.overlay {
    @apply fixed w-full h-full flex justify-center items-center z-[1000] left-0 top-0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .spinner {
    @apply w-32 h-32 bg-[#E88A00] rounded-2xl relative flex justify-center items-center;
    transition: all 0.5s ease-in-out;
  }

  .shrink {
    @apply w-16 h-16;
  }

  .symbol, 
  .logo {
    @apply w-10 h-10 absolute left-2/4 top-2/4;
    transition: top 0.8s, left 0.8s;
    transform: translate(-50%, -50%);
  }
  
  .logo img {
    @apply w-10 h-10 object-contain;
  }

  .symbol img {
    @apply w-full h-full object-contain;
  }

  .symbol1 {
    @apply w-6 h-6 left-1/4 top-1/4;
  }

  .symbol2 {
    @apply left-3/4 top-1/4;
  }

  .symbol3 {
    @apply w-6 h-6 left-3/4 top-3/4;
  }

  .symbol4 {
    @apply left-1/4 top-3/4;
  }

  .hidden {
    @apply invisible;
  }
  
  .visible {
    visibility: visible;
  }
  
  @keyframes shrink {
    0% {
      width: 128px;
      height: 128px;
    }
    100% {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }