.productContainer {
  @apply flex flex-col items-center gap-4 self-stretch pt-5 pb-10 px-0;
}
.homeRow {
  @apply bg-[var(--white,_#fff)] inline-flex flex-col justify-center items-start gap-1 w-[905px] rounded-lg;
}
.itemExpansion {
  @apply flex max-h-0 overflow-hidden justify-between self-stretch rounded [transition:max-height_0.5s_ease-in-out,_opacity_0.5s_ease-in-out,_padding_0.5s_ease-in-out];
}
.itemExpansion.expanded {
  @apply max-h-fit justify-between items-center self-stretch rounded px-8 py-6;
}
#upArrow {
  @apply flex justify-center items-center w-full h-7 cursor-pointer pb-4;
}
#upArrow p{
  @apply text-sky-600 text-sm font-medium font-['Roboto'];
}
#expansionContainer {
  @apply flex flex-row w-full p-[15px];
}
#expansionCancelButton {
  @apply w-2.5 h-2.5 cursor-pointer mr-2.5 mt-2.5;
}
.dataPointTitle {
  @apply text-[13px] w-full overflow-hidden text-ellipsis text-left break-words;
}
.mostMentionedDataPoint {
  @apply text-[13px] flex flex-row items-end w-[100%] h-[34px] justify-between mt-2.5;
}
.dataPointBar {
  @apply ml-[5px];
}
#recommendationContainer {
  @apply flex flex-col items-center gap-2 self-stretch pb-8;
}
.recommendationTitleContainerHorizontal {
  @apply flex justify-center items-center;
}
#cardScrollButton {
  @apply w-6 h-6 overflow-visible cursor-pointer;
}
#recommendationListCards {
  @apply flex items-center gap-[15px] ;
}
#recommendedProductContainer {
  @apply flex h-[173px] w-[120px] flex-col items-center gap-3 shrink-0 rounded shadow-[0px_2px_6px_0px_rgba(0,0,0,0.25)] animate-[popup_0.5s_ease-out_forwards] cursor-pointer pt-3 pb-2.5 px-3 hover:shadow-[0px_4px_10px_0px_rgba(0,0,0,0.3)] hover:scale-[1.15] bg-[var(--Foundation-Orange-orange-50,_#fff5e6)];
  /* Drop-down shadow 2,6,0.25 */
}
#recommendedProductContainer:hover {
  /* Hover effect styles */
  @apply scale-[1.033] [box-shadow:0px_4px_10px_0px_rgba(0,_0,_0,_0.3)] ease-in-out duration-300;
}

/* Meta Media Styles */
.mediaContainer {
  @apply w-[450px] h-[237px] flex-col justify-start items-start gap-1.5 inline-flex;
}
.mediaListCards {
  @apply h-[212px] py-3 justify-start items-center gap-4 flex;
}

/*This animation defines the popup effect of the recommendation card*/
@keyframes popup {
  from {
    @apply opacity-0 scale-[0.8];
  }
  to {
    @apply opacity-100 scale-100;
  }
}
.recommendationTitle {
  @apply self-stretch h-[19px] pl-7 justify-start items-start gap-2 inline-flex text-black text-base font-medium font-['Roboto'];
}
#cardPrice {
  @apply text-[color:var(--87-black-for-main-text,#212121)] text-lg not-italic font-medium leading-[normal];
}
#cardSeller {
  @apply text-[color:var(--87-black-for-main-text,#212121)] text-xs not-italic font-normal leading-[normal] overflow-hidden text-ellipsis justify-center items-center text-center break-words;
}
.recommendationDescriptionTextContainer {
  @apply flex text-center gap-1;
}
.recommendedProductDescription {
  @apply flex h-10 w-24 flex-col items-start gap-1 shrink-0 self-stretch;
}
.recommendationDescriptionText {
  @apply overflow-hidden text-ellipsis text-[color:var(--87-black-for-main-text,#212121)] text-xs not-italic font-normal leading-[normal] text-left;
}
.recommendationDescriptionCheck {
  @apply w-3 h-3;
}
#recommendedProductButton {
  @apply flex w-3/5 max-h-[25px] justify-center gap-1.5 flex-[1_0_0] rounded fixed pt-1.5 pb-1 px-2 bottom-2.5 bg-[var(--Foundation-Orange-orange-300,_#ffba54)];
}
#recommendedProductButtonText {
  @apply text-[color:var(--87-black-for-main-text,#212121)] text-[10px] font-bold leading-[normal];
}
.sort-option {
  @apply flex -order-1 flex-row items-center z-[1];
}
.review__analysis {
  @apply w-[30%] h-[500px] bg-[white] z-[1] mr-2.5;
}
.background_blue_gradient {
  @apply flex flex-col items-center w-full h-full bg-center bg-no-repeat bg-[linear-gradient(to_right,rgba(9,26,81,0.895),rgb(20,121,215))];

  /* Center the image */
}
#mostMentionedOpinionsContainer {
  @apply flex w-[346px] flex-col items-start gap-3 cursor-pointer pt-4 pb-3 px-4 rounded-lg hover:shadow-[0_4px_8px_rgba(0,0,0,0.1)] bg-[var(--Foundation-Grey-grey-50,_#f5f5f5)];
}
.reviewHighlights {
  @apply text-[color:var(--87-black-for-main-text,#212121)] text-right text-base not-italic font-medium leading-[normal];

  /* Body/b2: Medium */
}
#productDetailContainerFooter{
  @apply flex flex-col bg-neutral-100 rounded-lg w-full p-4;
}
.specsContainer {
  @apply hidden
}
.activeTab {
  @apply font-medium underline text-[#2e3f9e] underline-offset-8 text-sm;
}
.unactiveTab{
  @apply text-sm font-medium
}
.reviewHighlightsContainer {
  @apply flex flex-col h-3/5 w-[100%];
}
.dataPointTextGroup {
  @apply flex flex-col items-start gap-1 w-32;
}
.dataPointContent {
  @apply w-[170px] text-[color:var(--87-black-for-main-text,#212121)] text-sm not-italic font-[550] leading-[normal];

  /* Body/b3: Regular */
}
.dataPointFrequency {
  @apply text-[color:var(--Foundation-Grey-grey-900,#424242)] text-xs not-italic font-normal leading-[normal];

  /* Body/b4: Regular */
}
.sentimentBarContainer {
  @apply flex flex-row items-center text-black;
}
.viewMoreTextContainer {
  @apply flex h-4 w-max items-center self-stretch pr-[247px];
}
.viewMoreText {
  @apply text-[color:var(--Foundation-Blue-blue-600,#1E89DD)] cursor-pointer text-[15px] not-italic font-medium leading-[normal];

  /* Body/b3: Medium */
}
.resourcesGroup {
  @apply flex justify-between items-center self-stretch w-full h-6;
}
.resourcesImgGroup {
  @apply flex items-center gap-2 w-fit h-6;
}
.resourcesText {
  @apply text-[color:var(--87-black-for-main-text,#212121)] font-[Roboto] text-xs not-italic font-normal leading-[normal];

  /* Body/b4: Regular */
}
.recommendedLogo {
  @apply flex w-4 h-4;

  /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);*/
}
#load_more_button {
  @apply bg-[rgba(255,255,255,0.5)] w-[100px] h-[30px] text-[white] cursor-pointer mb-5 rounded-[20px] border-[none];
}

@media (max-width: 800px) {
  .productContainer {
    @apply flex flex-col items-center gap-4 self-stretch pt-10 pb-10 px-0;
  }
  .homeRow {
    @apply bg-[var(--white,_#fff)] inline-flex flex-col justify-center items-start gap-1 w-[90%] overflow-x-hidden rounded-lg;
  }
  .itemExpansion.expanded {
    @apply flex flex-col justify-between items-center self-stretch rounded p-[5%];
  }
  #mostMentionedOpinionsContainer {
    @apply flex w-full flex-col items-start gap-3 cursor-pointer pt-4 pb-3 px-4 rounded-lg bg-[var(--Foundation-Grey-grey-50,_#f5f5f5)];
  }
  .mostMentionedWrapper{
    @apply hidden
  }
  .specsContainer {
    @apply flex flex-col items-start gap-4 w-full text-black
  }
  #recommendationContainer {
    @apply flex flex-col items-center gap-2 self-stretch pt-2.5;
  }
  #recommendedProductContainer {
    @apply flex h-[173px] w-[130px] flex-col items-center gap-3 shrink-0 rounded shadow-[0px_2px_6px_0px_rgba(0,0,0,0.25)] animate-[popup_0.5s_ease-out_forwards] cursor-pointer overflow-hidden p-[4%] bg-[var(--Foundation-Orange-orange-50,_#fff5e6)];
  }
  #recommendationListCards {
    @apply flex items-center gap-[10px] w-[300px] overflow-scroll;
  }
  .recommendationTitleContainerHorizontal {
    @apply flex justify-center items-center w-full left-[-10rem];
  }
  #recommendationTitle {
    @apply self-stretch relative left-7 justify-start items-start gap-2 inline-flex pb-8 text-black text-opacity-20 text-base font-medium font-['Roboto'] leading-[normal];
  }
  #cardSeller {
    @apply text-[color:var(--87-black-for-main-text,#212121)] text-[9px] not-italic font-normal leading-[normal] overflow-hidden text-ellipsis justify-center items-center text-center break-words;
  }
  .recommendationDescriptionCheck {
    @apply w-2 h-2;
  }
  .recommendationDescriptionText {
    @apply overflow-hidden text-ellipsis text-[color:var(--87-black-for-main-text,#212121)] text-[8px] not-italic font-normal leading-[normal] text-left;
  }
  .resourcesText {
    @apply text-[color:var(--87-black-for-main-text,#212121)] font-[Roboto] text-xs not-italic font-normal leading-[normal];
  }
  .recommendedLogo {
    @apply flex w-[15px] h-[15px];

    /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);*/
  }
  #recommendedProductButtonText {
    @apply text-[color:var(--87-black-for-main-text,#212121)] text-[8px] font-bold leading-[normal];
  }
  .mediaContainer {
    @apply w-full h-[237px] flex-col justify-center items-center gap-1.5 inline-flex;
  }
  .mediaListCards {
    @apply w-[300px] h-[212px]  py-3 justify-start items-center gap-4 flex;
  }
}
