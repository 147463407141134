.sortOptionText {
    @apply border-[white] w-[15rem] border-[1px] gap-1 relative border-solid p-1 rounded-[5px] flex items-center left-[-20.7rem];
}
  
.sortOptionText label {
    @apply block text-[white] text-sm w-[5rem];
}

.dropdownButton {
    @apply border-[white] text-left inline-block w-full pr-[10px] py-[8px] rounded-[4px] appearance-none bg-[url('/assets/arrow-down.svg')] bg-[right_10px_center] bg-no-repeat [background-size:15px] cursor-pointer border-[unset];
    @apply text-[14px] whitespace-nowrap text-ellipsis overflow-hidden text-[white];
}
  
.dropdownMenu {
    @apply w-full text-sm text-black rounded-[4px] absolute bg-white border shadow-[0px_8px_16px_0px_rgba(0,0,0,0.2)] z-[1] left-0 top-full;
}

.dropdownMenu li {
    @apply hover:bg-[#f1f1f1] cursor-pointer px-3 py-2 list-none text-black;
}

.dropdownMenu li:hover
.dropdownMenu li:focus {
    @apply bg-[#ddd] outline-none;
}

@media (max-width: 800px) {
    .sortOptionText {
        @apply border-[white] max-w-md border-[3px] gap-1 relative border-solid p-1 rounded-[5px] flex items-center self-start ml-[5%];
    }

    .sortOptionText label {
        @apply text-[white] text-sm w-[80px];
    }

    .dropdownButton {
        @apply border-[white] text-left inline-block w-fit pl-[10px] pr-[35px] py-[8px] rounded-[4px] appearance-none bg-[url('/assets/arrow-down.svg')] bg-[right_10px_center] bg-no-repeat [background-size:20px] cursor-pointer border-[unset];
        @apply text-[14px] whitespace-nowrap text-ellipsis overflow-hidden text-[white];
    }
}
