.mediaCardContainer {
    @apply relative w-[186px] h-[188px] bg-white rounded flex-col justify-start items-start inline-flex shadow-[0px_2px_6px_0px_rgba(0,0,0,0.25)] hover:shadow-[0px_4px_10px_0px_rgba(0,0,0,0.3)] hover:scale-[1.033] ease-in-out duration-300;
  }
  .videoTitle {
    @apply pt-2 pl-2 pr-2 opacity-80 text-ellipsis self-stretch text-black text-[13px] font-semibold font-['Roboto'] leading-tight overflow-hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .blogTitle {
    @apply mt-10 pt-2 pl-2 pr-2 text-black text-[13px] font-semibold font-['Roboto'] leading-tight opacity-80 overflow-hidden text-ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .blogDesc {
    @apply pt-2 pl-2 pr-2 text-black text-[11px] font-normal font-['Roboto'] leading-tight opacity-80 overflow-hidden text-ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .tag_video {
    @apply absolute top-2 left-2 z-10 px-2 py-1 bg-purple-100 rounded-xl justify-start items-start self-stretch gap-2 inline-flex;
  }
  .tag_blog {
    @apply absolute top-2 left-2 z-10 px-2 py-1 bg-blue-200 rounded-xl justify-center items-center self-stretch gap-2 inline-flex;
  }
  .tagText {
    @apply text-neutral-700 text-[11px] font-normal font-['Roboto'];
  }
  .otherInfo {
    @apply absolute bottom-2 left-2 right-2 z-10 flex justify-between items-center text-white text-xs opacity-80;
  }
  .mediaViews {
    @apply text-red-600 text-xs font-medium font-['Roboto'];
  }
  .mediaDate {
    @apply text-right text-zinc-600 text-xs font-normal font-['Roboto'];
  }
  