#productDetailContainer {
    @apply flex items-center gap-[25px] bg-gray-100 rounded-lg p-4;
    min-width: 44rem;
    max-width: 44rem;
}

.productDetailColumn {
    @apply flex flex-col items-start gap-2 w-[30%] overflow-hidden whitespace-nowrap text-ellipsis;
}

.productDetailRow {
    @apply flex items-center gap-0.5 rounded;
}

.productDetailIcon {
    @apply w-6 h-6;
}

.productDetailText {
    @apply text-[color:var(--87-black-for-main-text,#212121)] text-[13px] not-italic font-light h-[1.2em] text-ellipsis leading-[normal] whitespace-nowrap overflow-hidden w-fit;
    /* Body/b4: Regular */
}

@media (max-width: 768px) {
    #productDetailContainer {
        @apply flex items-center gap-[5px] w-full;
    }

    .productDetailColumn {
        @apply flex flex-col items-start gap-2 w-full overflow-hidden whitespace-nowrap text-ellipsis;
    }

    .productDetailIcon {
        @apply w-[18px] h-[18px];
    }

    .productDetailText {
        @apply text-[color:var(--87-black-for-main-text,#212121)] text-[13px] not-italic font-light text-ellipsis leading-[normal] whitespace-nowrap overflow-scroll w-fit;
        /* Body/b4: Regular */
    }
}