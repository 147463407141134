.prosConsContainerFooter {
    @apply flex flex-col bg-gray-100 rounded-lg;
}

.prosConsContainer {
    @apply flex flex-col items-center gap-[25px] bg-neutral-100 rounded-lg p-4;
    border: 10px;
    width: 100%;
}

.resourcesGroupFooter{
    @apply flex items-center self-stretch w-full h-6 gap-4 mb-2;
    margin-left: 1rem;
}

.resourcesText {
    @apply text-[color:var(--87-black-for-main-text,#212121)] text-xs not-italic font-light leading-[normal];
}

.resourcesImgGroup {
    @apply flex items-center gap-2 w-fit h-6;
}

@media (max-width: 768px) {
    .recommendedLogo {
        @apply flex w-[15px] h-[15px];
    }
}