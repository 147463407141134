
  /* .homePage {
    @apply flex flex-col items-center self-stretch gap-10 h-screen bg-[radial-gradient(circle_at_-50%_-50%,rgba(255,255,255,1),rgba(4,1,1,0.43)),linear-gradient(319deg,rgba(10,138,249,1),rgba(0,0,0,1)),url("data:image/svg+xml,%3Csvg_viewBox='0_0_251_251'_xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter_id='noiseFilter'%3E%3CfeTurbulence_type='fractalNoise'_baseFrequency='5.29'_numOctaves='3'_stitchTiles='stitch'/%3E%3C/filter%3E%3Crect_width='100%25'_height='100%25'_filter='url(%23noiseFilter)'/%3E%3C/svg%3E")] mx-auto;
} */

/* .homePage {
  @apply flex flex-col items-center self-stretch gap-10 h-screen h-[93.5vh] bg-[radial-gradient(circle_at_-50%_-50%,rgba(255,255,255,1),rgba(4,1,1,0.43)),linear-gradient(319deg,rgba(10,138,249,1),rgba(0,0,0,1)),url("data:image/svg+xml,%3Csvg_viewBox='0_0_251_251'_xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter_id='noiseFilter'%3E%3CfeTurbulence_type='fractalNoise'_baseFrequency='5.29'_numOctaves='3'_stitchTiles='stitch'/%3E%3C/filter%3E%3Crect_width='100%25'_height='100%25'_filter='url(%23noiseFilter)'/%3E%3C/svg%3E")] mx-auto;
} */

.homePage {
  @apply flex flex-col items-center self-stretch gap-10 h-screen h-[93.5vh] mx-auto;
  background-image: 
  radial-gradient(circle at 100% 100%, rgb(21, 74, 107) 0%, rgba(21, 74, 107, 0) 40%),
    radial-gradient(circle at 0% 0%, rgba(45, 33, 88, 1) 0%, rgba(30, 41, 59, 0) 40%),
    linear-gradient(90deg, rgba(13, 14, 56, 1) 0%, rgba(13, 14, 56, 1) 100%),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 251 251' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.29' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  background-size: 100% 100%, 100% 100%, 100% 100%, 251px 251px;
  background-position: center, center, center, center;
  background-repeat: no-repeat, no-repeat, no-repeat, repeat;
}

.center_content{
  @apply flex flex-col items-center justify-center gap-12 h-[100vh] w-full;
}

.firstPage__text {
  @apply flex flex-col justify-center items-center gap-[16px] w-[85%];
}

.title__text {
  @apply text-[color:var(--white,#FFF)] text-center text-[48px] not-italic font-bold leading-[55px] [contain:content] [font-display:swap];
}

.sub__title__text {
  /* Heading/h4: Light */
  font-display: swap;
  @apply text-[color:var(--white,#FFF)] text-center text-xl font-extralight leading-10; 
}

.sub__title__beta__text {
  /* Heading/h4: Light */
  @apply text-[color:var(--white,#FFF)] text-center font-[Arial,_'Helvetica_Neue',_Helvetica,_sans-serif] text-[20px] not-italic font-light leading-[200%];
}

.loading {
  @apply flex items-center justify-center w-full h-screen bg-center bg-no-repeat my-5;
  background-image: 
  radial-gradient(circle at 100% 100%, rgb(21, 74, 107) 0%, rgba(21, 74, 107, 0) 40%),
    radial-gradient(circle at 0% 0%, rgba(45, 33, 88, 1) 0%, rgba(30, 41, 59, 0) 40%),
    linear-gradient(90deg, rgba(13, 14, 56, 1) 0%, rgba(13, 14, 56, 1) 100%),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 251 251' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='5.29' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  background-size: 100% 100%, 100% 100%, 100% 100%, 251px 251px;
  background-position: center, center, center, center;
  background-repeat: no-repeat, no-repeat, no-repeat, repeat;
}
  
  @media (max-width: 800px) {
    .sub__title__text {
        @apply text-[color:var(--white,#FFF)] text-center text-base font-[240] leading-normal w-[93%];  
    }
  
    .title__text {
        @apply text-[color:var(--white,#FFF)] text-center text-[26px] font-bold leading-10;
    }

    .firstPage__text {
      @apply flex flex-col justify-center items-center gap-[16px] w-[90%];
    }
  }
  
